import './libs/fancybox/dist/jquery.fancybox.min.js';
import './libs/owl.carousel.min.js';



$.fancybox.defaults.btnTpl.counter = '<div><span data-fancybox-index></span> / <span data-fancybox-count></span></div>';

$('#before-slider').owlCarousel({
    loop: true,
    items: 1,
    margin: 10,
    nav: true,
    dots: true,
    navText: ['', '']
});
    
$('#community .carousel').owlCarousel({
    loop: true,
    margin: 15,
    nav: false,
    dots: true,
    dotsContainer: '#owl-dots',
    responsive:{
        0:{ items:2, stagePadding: 40 },
        500:{ items:3, stagePadding: 40 },
        700:{ items:4, stagePadding: 40 },
        900:{ items:5 },
        1200:{ items:6 },
        1400:{ items:7 }
    } 
});

$('#afterparty-slider').addClass('owl-carousel').owlCarousel({
    loop: true,
    items: 1,
    margin: 0,
    nav: true,
    dots: true,
    navText: ['', ''],
    center: true,
    responsive: {
      620: {
        items: 3,
        startPosition: 1
      },
      768: {
        items: 4
      }
    }
});

$('#before-slider .item').fancybox({
    selector : '#before-slider .owl-item:not(.cloned) a',
    backFocus : false,
    infobar : false,
    loop: true,
    thumbs : {
        autoStart : true,
        axis : 'x'
    }
});

$('#afterparty-slider .item').fancybox({
    selector : '#afterparty-slider .owl-item:not(.cloned) a',
    backFocus : false,
    infobar : false,
    loop: true,
    thumbs : {
        autoStart : true,
        axis : 'x'
    }
});
