export class Navigation {
  constructor (el) {
    this._element = $(el)
    this._classes = {
      active: 'active',
      tempAnimate: 'closed'
    }
  }
  _animate (activeClass = this._classes.active,
    tempClass = this._classes.tempAnimate,
    duration = 0) {
    this._element.addClass(tempClass)
    setTimeout(() => {
      this._element.removeClass(tempClass + ' ' + activeClass)
    }, duration)
    return this
  }
  isHome () {
    return $('body').hasClass('home')
  }
  scrollTo (anchor, duration) {
    try {
      let section = $(anchor)

      let dur = duration || this.getDuration()

      $('html, body').animate({
        scrollTop: section.offset().top
      }, dur)
      location.hash = anchor.replace(/[.]*/gi, '')
    } catch (err) {
      throw new Error('Anchor "' + anchor + '" is not found')
    }
    return this || {}
  }
  getDirection (upFunc = () => {}, downFunc = () => {}) {
    var lastScrollTop = 0
    $(window).scroll(function (event) {
      if ($(window).innerWidth() < 768) {
        var st = $(this).scrollTop()
        if (st > lastScrollTop) {
          downFunc()
        } else if (st === lastScrollTop) {
          // do nothing
          // In IE this is an important condition because there seems to be some instances where the last scrollTop is equal to the new one
        } else {
          upFunc()
        }
        lastScrollTop = st
      }
    })
  }
  getDuration () {
    return window.innerWidth < 768 ? 0 : 300
  }
  open (activeClass = this._classes.active, duration = 0) {
    this._element.addClass(activeClass, duration)
    return this || {}
  }
  close (activeClass = this._classes.active, tempClass = this._classes.tempAnimate, duration) {
    let dur = duration || this.getDuration()
    this._animate(activeClass, tempClass, dur)
    return this || {}
  }
}

export class Menu extends Navigation {
  constructor (el) {
    super(el)
    this._items = this._element.find('.menu__link')
  }
  items () {
    return this._items || {}
  }
  getItem (anchor) {
    try {
      let item = this.items().filter(function () {
        return $(this).data('href') === anchor
      })

      return item || {}
    } catch (err) {
      throw new Error('Anchor "' + anchor + '" is not found')
    }
  }
  isActive () {
    return this._element.hasClass(this._classes.active)
  }
  getAnchor (item) {
    try {
      return item.data('href')
    } catch (err) {
      throw new Error('Anchor "' + item.data('href') + '" is not found')
    }
  }
  setActive (itemHash, activeClass = this._classes.active) {
    this.items().map(function () {
      $(this).removeClass(activeClass)
    })
    let item = this.getItem(itemHash).addClass(activeClass)
    return item || {}
  }
}

$(window).on('load', function () {
  let menu = new Menu('#menu')

  let mobileMenu = new Menu('.menurger__wrap')

  let pageNavigation = new Navigation('body')

  $('.menurger').on('click', function (e) {
    e.preventDefault()
    if (menu.isActive()) {
      menu.close()
    } else {
      menu.open()
    }
  })
  $('.home__more').on('click', function () {
    pageNavigation.scrollTo($(this).attr('href'), 300)
  })
  pageNavigation.getDirection(() => {
    // up scroll
    if ($(window).scrollTop() < 200) {
      mobileMenu.close('mobile')
    } else {
      mobileMenu.open('mobile')
    }
  }, () => {
    // down scroll
    mobileMenu.close('mobile')
  })
  menu.items().on('click', function (e) {
    if (menu.isHome()) {
      e.preventDefault()
      let anchor = menu.getAnchor($(this))
      pageNavigation.scrollTo(anchor)
      menu.close().setActive(anchor)
    }
  })

  $('.menu__close, .menu__overlay').on('click', function (e) {
    e.preventDefault()
    menu.close()
  })
  $(document).on('keydown', function (e) {
    if (menu.isActive()) {
      switch (e.keyCode) {
        case 27: // esc
          menu.close()
          break
      }
    }
  })
  $('.sponsors__button').on('click', function (e) {
    e.preventDefault()

    $('.packages__tab').removeClass('selected')
    $('.packages__full-wrap').removeClass('platinum party premium exhibitor')
    $('.packages__full.selected').removeClass('selected')

    var tarif = $(this).data('tarif')
    $('.packages__tab-' + tarif).addClass('selected')
    $('.packages__tabs').addClass('small')
    $('.packages__full-wrap').addClass('active ' + tarif)
    $('#' + tarif).addClass('selected')

    pageNavigation.scrollTo('.packages', 500)
  })
})
